<template>
  <div class="content">
    <breadcrumb
      :path="{'Lainaa': '/lainaa', 'Lainaa 30 000 euroa': ''}"
    />
    <div class="subpage_floating">
      <img :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
      <sliders :luottoraja="form.luottoraja" :maksuaika="form.maksuaika" class="is-hidden-touch" />
    </div>
    <h1>Lainaa 30000 € alkaen 253 € per kuukausi</h1>
    <p>
      Tarvitsetko 30000 euroa lainaa? Anna Rahalaitoksen etsiä sinulle edullisin laina! Kilpailutamme jopa yli 25 eri rahoituslaitosta puolestasi, sinun ei tarvitse kuin täyttää hakemus sivustomme kautta.
    </p>
    <sliders :luottoraja="form.luottoraja" :maksuaika="form.maksuaika" class="is-hidden-desktop" />
    <h2>Säästä aikaa ja selvää rahaa Rahalaitoksen avulla</h2>
    <p>Kilpailutamme rahoituslaitokset puolestasi ja varmistamme että saat edullisimman lainan. Sinun kannattaa käyttää palveluamme koska:</p>
    <ul>
      <li>hakemuksen täyttämiseen menee vain muutama minuutti,</li>
      <li>voit verrata lainatarjouksia reaaliaikaisesti,</li>
      <li>lainasummat aina 70 000 euroon saakka (ilman vakuuksia),</li>
      <li>saat luottopäätöksen heti ja rahat jopa saman päivän aikana,</li>
      <li>lainan hakeminen on ilmaista, eikä se sido sinua mihinkään.</li>
    </ul>
    <p>
      Lainojen hinnoissa voi olla suuriakin eroja, ja siksi sinun kannattaa <nuxt-link title="Kilpailuta lainasi Rahalaitoksen avulla" to="/kilpailuta-lainat">kilpailuttaa lainasi</nuxt-link> ilmaiseksi meidän kautta. Parhaassa tapauksessa lainojen vertaaminen voi säästää jopa tuhansia euroja kuluissa!
    </p>
    <h3>Lainaa ilman vakuuksia</h3>
    <p>
      Rahalaitoksen kautta voit hakea 30000 euroa <nuxt-link title="Lue lisää vakuudettomista lainoista" to="/lainaa-ilman-vakuuksia">lainaa ilman vakuuksia</nuxt-link> tai takaajia, esim. auton ostoa tai suurempaa kotiremonttia varten. Meidän kautta onnistuu myös <nuxt-link title="Lainojen yhdistäminen kannattaa" to="/yhdista-lainat">vanhojen lainojen yhdistäminen yhdeksi</nuxt-link> (kulujen säästämiseksi). Sinun kannattaa miettiä tätä vaihtoehtoa jos sinulla on useita maksamattomia pikavippejä tai kulutusluottoja odottamassa.
    </p>
    <h3>Laina-aika jopa 15 vuotta</h3>
    <p>
      Koska 30000 euroa on lainasummana jo melko suuri, tarjoavat pankit jopa 15 vuotta maksuaikaa. Mieti tarkkaan kuinka pitkän takaisinmaksuajan tarvitset lainallesi. Lyhyempi laina-aika tarkoittaa suurempia kuukausieriä, mutta pienempiä kokonaiskuluja. Pidempi laina-aika antaa sinulle enemmän vapautta koska kuukausierät ovat pienempiä, mutta lainan kokonaiskulut ovat suuremmat.
    </p>
    <h3>Saat luottopäätöksen heti ja rahat tilille jopa saman päivän aikana</h3>
    <p>
      Kumppaneidemme lainatarjoukset toimitetaan sinulle usein jo saman päivän aikana. Voit rauhassa tutustua saamiisi tarjouksiin ja verrata mm. lainojen kuluja. Kun olet löytänyt sinulle sopivan lainan, päätät itse hyväksytkö tarjouksen vai et (palvelun käyttö ei siis sido sinua mihinkään). Hyväksyttyäsi lainatarjouksen, rahat siirretään tilillesi joko samana päivänä tai viimeistään muutaman arkipäivän kuluessa.
    </p>
  </div>
</template>

<script>
import sliders from '~/components/subpage/sliders'

export default {
  layout: 'subpage',
  name: 'Lainaa30000Euroa',
  components: {
    sliders,
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  data () {
    return {
      form: {
        luottoraja: 30000,
        maksuaika: 7
      }
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa 40 000 euroa',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-40000-euroa'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      },
      {
        title: 'Kilpailuta pohjoismaiset pankit',
        subtitle: 'Vertaile ja katso mistä saat edullisimman lainan',
        link: '/talous/suomalainen-ruotsalainen-vai-norjalainen-pankki',
        image: '/cms/uploads/large_photo_1538332576228_eb5b4c4de6f5_3bdf11ac3a.jpg'
      }
    ] )
  },
  head () {
    return {
      title: 'Lainaa 30000 euroa ilman vakuusia | Alkaen 253 €/kk',
      meta: [
        { hid: 'description', name: 'description', content: 'Rahalaitos kilpailuttaa pankit ja rahoituslaitokset puolestasi ilmaiseksi. Täytä hakemus jo tänään ja hae lainaa 30000 euroa!' }
      ]
    }
  }
}
</script>
