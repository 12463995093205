<template>
  <div class="content">
    <breadcrumb
      :path="{'Talous': '/talous', 'Pitkään odotettu reissu edessä': ''}"
    />
    <div class="subpage_floating">
      <img :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
      <sliders class="is-hidden-touch" />
    </div>
    <h1>Pitkään odotettu reissu edessä ja rahat vähissä? Tiesitkö: Unelmaloman kulut voi maksaa myös erissä</h1>
    <h5>Matkan maksaminen lainalla mahdollistaa sen, että kulut voi jakaa tasaisemmin useamman kuukauden ajalle.</h5>
    <p>
      Monilla suomalaisilla on pian edessä ehkä jopa vuoden odotetuin hetki. Kesälomamatkat vievät perheitä viettämään laatuaikaa joko kotimaassa tai ulkomaan lomakohteissa, mutta unelmaloma auringossa menee helposti pieleen, jos lomabudjetti on hyvin tiukka, eikä rahaa riitä mihinkään ylimääräiseen. Rahasta riitely tai stressaaminen ei kuulu onnistuneeseen lomaan.
    </p>
    <sliders class="is-hidden-desktop" />
    <p>
      Matkustaminen ei ole edullista puuhaa, varsinkaan suosittuun loma-aikaan, ja esimerkiksi nelihenkisen perheen lomaan saa helposti uppoamaan useita tuhansia euroja. On täysin ymmärrettävää, että kaikilla ei ole tällaista summaa valmiina, varsinkin kun sen lisäksi rahaa pitäisi jäädä myös lomasta nauttimiseen.
    </p>
    <h2>
      Vähemmän stressiä, enemmän maksuaikaa
    </h2>
    <p>
      Olemme jo melko tottuneita maksamaan talouden isommat hankinnat, kuten kodinkoneet, remontit tai huonekalut, lainarahalla. Uusien, entistä helpompien laina- ja luottomahdollisuuksien myötä matkojen maksaminen erissä lainan avulla on yleistynyt.
    </p>
    <p>
      Myös Suomen johtavalta lainanvälittäjältä <nuxt-link to="/"> Rahalaitokselta </nuxt-link> haetaan yhä useammin rahoitusta lomamatkojen kuluja varten.
    </p>
    <p>
      Käytännössä matkakulujen jakaminen eriin lainalla ei juurikaan poikkea luottokortilla maksamisesta. Erona on se, että halutun lainasumman saa kokonaisuudessaan tilille, ja näin on helpompi arvioida matkan kokonaiskulut. Rahat voi sitten käyttää matka- ja majoituskuluihin ja lomasta nauttimiseen parhaaksi näkemällään tavalla.
    </p>
    <h2>
      Luotettava ja suomalainen
    </h2>
    <p>
      Internet on nykyään täynnä erilaisia pankkeja ja lainanmyöntäjiä, ja siksi lainaa tarvitseva voi törmätä aikamoiseen runsauden pulaan. Mistä kannattaa hakea, ja miten voi tietää, että saatu tarjous on paras mahdollinen? Kuinka monta hakemusta täytyy lähettää, että saa tarpeeksi kattavan kuvan korkojen vaihtelusta ja eri lainanmyöntäjien ehdoista?
    </p>
    <p>
      Siksi helpointa on kääntyä luotettavan lainanvälittäjän puoleen, joka tekee hankalan työn puolestasi.
    </p>
    <p>
      <nuxt-link to="/"> Hakemalla </nuxt-link> lainaa Rahalaitoksen kautta varmistat, että saat aina parhaan mahdollisen lainatarjouksen. Täysin suomalainen lainanvälittäjä Rahalaitos kilpailuttaa hakemuksesi 25 pankilla ja lainantarjoajalla, ja löytää siksi aina parhaan mahdollisen tarjouksen juuri sinulle. Voit siis hakea yhdellä hakemuksella lainaa 500 eurosta aina 70 000 euroon asti.
    </p>
    <p>
      Mahdolliset tarjoukset saat suoraan Oma Rahalaitos -nettipalveluun, missä voit tutustua niihin rauhassa. Lainan hakeminen on maksutonta, eikä velvoita sinua vielä mitenkään. Saat itse päättää, hyväksytkö jonkun saamistasi tarjouksista.
    </p>
    <p>
      Lainaa kannattaa aina ottaa harkiten, ja miettiä ennen hakemuksen täyttöä, millaisen summan pystyy maksamaan takaisin ilman, että takaisinmaksuerät kasvavat liian suuriksi.
    </p>
    <p>
      Tässä auttaa Rahalaitoksen sivuilta löytyvä <nuxt-link to="/">lainalaskuri</nuxt-link>, johon voi syöttää tarvitsemansa lainan määrän ja maksuaikaa säätämällä nähdä arvioidun kuukausilyhennyksen määrän.
    </p>
    <p>
      <nuxt-link to="/"> Täytä lainahakemus muutamassa minuutissa – Rahalaitos kilpailuttaa sen puolestasi. </nuxt-link>
    </p>

    <share text="Pitkään odotettu reissu edessä ja rahat vähissä? Tiesitkö: Unelmaloman kulut voi maksaa myös erissä" />
  </div>
</template>

<script>
import sliders from '~/components/subpage/sliders'
import share from '~/components/subpage/share'

export default {
  layout: 'articles',
  name: 'PitkaanOdotettuReissu',
  components: {
    sliders,
    share,
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: 'Lainaa matkaa varten | Maksa unelmaloman kulut erissä',
      meta: [
        { hid: 'description', name: 'description', content: 'Matkan maksaminen lainalla mahdollistaa sen, että kulut voi jakaa tasaisemmin useamman kuukauden ajalle. Lue lisää ja hae lainaa!' }
      ]
    }
  }
}
</script>
