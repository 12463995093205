<template>
  <div :class="{ 'is-active': active }" class="modal settings-modal">
    <div class="modal-background" @click="$emit('close')" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Asetukset
        </p>
        <button class="delete" aria-label="close" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="content">
          <p class="has-text-weight-bold">Haluan saada tietoa tarjouksista tekemääni lainahakemusta koskien</p>
          <div class="columns">
            <div class="column is-8 is-4-desktop">
              <form-switch
                key="settings.reminderMessageEmail"
                v-model="settings.reminderMessageEmail"
                v-validate="'required'"
                :error="errors.first('reminderMessageEmail')"
                name="reminderMessageEmail"
                label="Sähköpostitse"
              />
            </div>
            <div class="column is-8 is-4-desktop">
              <form-switch
                key="settings.reminderMessageSMS"
                v-model="settings.reminderMessageSMS"
                v-validate="'required'"
                :error="errors.first('reminderMessageSMS')"
                name="reminderMessageEmail"
                label="Tekstiviestitse"
              />
            </div>
          </div>
        </div>
        <div v-if="data.applicationSuccess" class="columns">
          <div class="column">
            <div class="notification is-success">
              <p>Asetuksien päivitys onnistui</p>
            </div>
          </div>
        </div>
        <div v-if="data.applicationError" class="columns">
          <div class="column">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="notification is-danger" v-html="data.applicationError" />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot" style="justify-content: space-between;">
        <button class="button is-rounded" @click="$emit('close')">
          Sulje
        </button>
        <button :disabled="data.submitLoading" class="button is-primary is-rounded" type="button" @click="submit">
          <span>Tallenna</span><span v-if="data.submitLoading" class="icon"><font-awesome-icon :icon="['fas', 'spinner-third']" spin /></span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'SettingsModal',
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    formSwitch: () => import( '~/components/form/switch' )
  },
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      data: {
        applicationError: null,
        applicationSuccess: false
      },
      settings: {
        reminderMessageEmail: null,
        reminderMessageSMS: null
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const result = await this.$axios.$get( '/v1/oma/?action=settings&method=get' )
        if ( _.has( result, 'settings' ) ) {
          this.settings.reminderMessageEmail = result.settings.reminderMessageEmail
          this.settings.reminderMessageSMS = result.settings.reminderMessageSMS
        }
      }
      catch ( error ) {
        this.$logger.error( error )
      }
    },
    async submit () {
      let result = {}
      this.data.submitLoading = true
      this.data.applicationError = null
      this.data.applicationSuccess = false
      try {
        result = await this.$axios.$post( '/v1/oma/?action=settings&method=set', this.settings )
      }
      catch ( error ) {
        this.$logger.error( error )
      }
      if ( _.has( result, 'success' ) && result.success ) {
        this.$ga.event( { eventCategory: 'omaSettings', eventAction: 'store', eventLabel: 'stored' } )
        this.$gtag.event( 'store', { event_category: 'omaSettings', event_label: 'stored' } )
        this.data.submitLoading = false
        this.data.applicationSuccess = true
        return
      }
      this.data.applicationError = `
        <strong>Järjestelmävirhe</strong>
        <p>Emme voineet tallentaa asetuksia, koska tapahtui odottamaton järjestelmävirhe.</p>
        <p>Pahoittelemme tapahtunutta. Yritä myöhemmin uudelleen. Mikäli ongelma toistuu edelleen, ota yhteyttä asiakaspalveluumme.</p>
      `
      this.$ga.event( { eventCategory: 'omaSettings', eventAction: 'store', eventLabel: 'error' } )
      this.$gtag.event( 'store', { event_category: 'omaSettings', event_label: 'error' } )
      this.data.submitLoading = false
    }
  }
}
</script>
