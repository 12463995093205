<template>
  <div class="content">
    <breadcrumb
      :path="{'Autolaina': '/autolaina', 'Auton hankinnassa voi säästää reilusti': ''}"
    />
    <img style="width: 100%;" :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
    <h1>Auton hankinnassa voi säästää reilusti kilpailuttamalla autolainat</h1>
    <p style="font-weight: 900;">
      Autorahoitusta tarjotaan niin pankeista kuin rahoitusyhtiöistä. Lainavertailun avulla on mahdollista säästää jopa tuhansia euroja. Silti harva autonostaja kilpailuttaa lainansa.
    </p>
    <p>
      Uuden auton ostaminen on aina iso hankinta ja yleensä auton hankintaa ei täysin pysty rahoittamaan omilla säästöillä. Tällöin tarjolla on erilaisia osamaksu-, rahoitus- ja lainaratkaisuja. Yleensä auton rahoitukseen tarvittava summa on niin suuri, että kannattaa tarkkaan laskea mistä lainan ottaa.
    </p>
    <p>
      Erilaisten autorahoituksen vaihtoehtojen vertailussa kannattaa huomioida kaikki lainaan liittyvät kustannukset ja muut tekijät kuten omistuksen siirtyminen. Koron lisäksi merkittävä osa lainan kokonaiskuluista kertyy erilaisista avausmaksuista tai tilinhoitomaksuista. Autolainan kulujen vertailuun saa helposti apua lainankilpailutuspalvelusta.
    </p>
    <p>
      Suomen johtava lainankilpailutuspalvelu <nuxt-link to="/">Rahalaitos</nuxt-link> hakee nopeasti useita tarjouksia autolainaa varten ja sen avulla on helppo vertailla erilaisia lainatarjouksia. Palvelu on täysin ilmainen eikä sen käyttö sitouta nostamaan lainaa sen kautta. Sadat suomalaiset autonostajat kilpailuttavat lainansa kuukausittain Rahalaitoksen palvelussa.
    </p>
    <p>
      <nuxt-link to="/autolaina">Autolainan</nuxt-link> kilpailuttamiseen lainanvälittäjän avulla on useita hyviä syitä.
    </p>
    <h2>
      1. Yhdellä hakemuksella useita tarjouksia
    </h2>
    <p>
      Sen sijaan, että tekisit usealle autolainaa tai rahoitusta tarjoavalle pankille tai rahoitusyhtiölle lainahakemuksen teet vain yhden hakemuksen, jonka kilpailutamme hetkessä jopa 25 eri pankin kesken.
    </p>
    <h2>
      2. Vertaa ja valitse
    </h2>
    <p>
      Saat nähtävillesi jopa minuuteissa sinulle tehdyt lainatarjoukset. Voit vertailla eri tarjouksia kätevästi yhdessä näkymässä ja valita sinulle sopivimman. Lainojen kilpailutus ja vertailu ei sido sinua mihinkään. Laina vahvistetaan vasta, kun olet hyväksynyt allekirjoituksellasi pankin tarjoamat lainaehdot ja nostanut lainan tilillesi.
    </p>
    <h2>
      3. Nopeaa ja helppoa
    </h2>
    <p>
      Hakemuksen täyttäminen vie vain minuutin. Ota valmiiksi tilitietosi, jolle lainan haluat maksettavan.
    </p>
    <p>
      Useimmat lainatarjoukset pääset hyväksymään pankkitunnuksillasi suoraan omilta sivuiltasi, missä tarjoukset ovat nähtävillä. Hakemastasi lainasummasta riippuen, valitsemasi laina maksetaan nopeimmillaan vielä saman päivän aikana tilillesi.
    </p>
    <h2>
      4. Auton ostaessasi et tarvitse vakuuksia, vaan auto siirtyy sinun omistukseesi heti.
    </h2>
    <p>
      Autorahoitussopimuksessa joudut usein asettamaan ostamasi auton lainan takaisinmaksun vakuudeksi. Rahalaitoksen kautta autolainan hakeminen onnistuu täysin ilman vakuuksia. Tämä helpottaa ja nopeuttaa myös auton myymistä, kun et ole joutunut laittamaan sitä autolainasi pantiksi. Lisäksi vakuudeton laina on yleensä helpompi järjestää, mikäli olet ostamassa autoa yksityiseltä myyjältä autoliikkeen sijaan.
    </p>
    <p>
      Mikäli sinulla on muita kortti- tai kulutusluottoja, kannattaa nämä ehdottomasti <nuxt-link to="/yhdista-lainat">yhdistää</nuxt-link> yhdeksi lainaksi. Tällöin maksat vain yhtä kuukausierää etkä useita päällekkäisiä korko- ja laskutuskuluja. Tämä merkitsee lähes aina merkittävää säästöä kuluissa.
    </p>
    <span class="miniform-header"> Lainan kilpailutus 70 000 € asti </span>
    <mini-form go-to-url="/autolaina" />
    <share text="Auton hankinnassa voi säästää reilusti kilpailuttamalla autolainat" />
  </div>
</template>

<script>
export default {
  layout: 'articles',
  name: 'AutonHankinta',
  components: {
    miniForm: () => import( '~/components/subpage/miniform' ),
    share: () => import( '~/components/subpage/share' ),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: 'Auton hankinnassa voi säästää reilusti kilpailuttamalla autolainat',
      meta: [
        { hid: 'description', name: 'description', content: 'Lainavertailun avulla on mahdollista säästää jopa tuhansia euroja. Lue, miten säästäminen onnistuu.' }
      ]
    }
  }
}
</script>
