<template>
  <div v-tooltip="tooltip" class="field">
    <div :class="{ 'has-float-label': label, 'has-icons-left': icon.length }" class="control">
      <div :class="[{ 'is-danger': error, 'is-static': isStatic }, selectClass]" class="select has-disabled is-fullwidth">
        <select
          :id="`select_${name}`"
          :class="[{ 'selected': value }, inputClass]"
          :name="name"
          :value="value"
          :disabled="disable"
          @change="$emit('input', $event.target.value)"
        >
          <option :selected="!value && value !== '0'" value="" disabled>{{ label || placeholder }}</option>
          <slot />
        </select>
        <label v-show="value" :for="`select_${name}`" class="label">{{ label }}</label>
      </div>
      <div v-if="icon.length" class="icon is-small is-left">
        <font-awesome-icon :icon="icon" />
      </div>
    </div>
    <p v-if="error" class="help is-danger">{{ error }}</p>
    <p v-if="help" class="help">{{ help }}</p>
  </div>
</template>

<script>

export default {
  $_veeValidate: {
    value () {
      return this.value
    },
    name () {
      return this.name
    },
    alias () {
      return this.label || this.placeholder || this.name
    },
    events: 'input'
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: null
    },
    error: {
      type: String,
      default: ''
    },
    selectClass: {
      type: String,
      default: ''
    },
    icon: {
      type: Array,
      default: () => []
    },
    isStatic: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: Object,
      default: () => {}
    },
    disable: {
      type: Boolean,
      default: false
    },
    help: {
      type: String,
      default: ''
    }
  }
}
</script>
