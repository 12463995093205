<template>
  <div class="feedback">
    <p class="title has-text-centered">Asiakas<span class="is-inline-mobile-xs" style="display: none;">-<br></span>palautteet</p>
    <div ref="slides" class="slides">
      <div v-for="(feedback, key) in feedbacks" :key="key" class="slide has-text-centered">
        <p class="is-6"><span class="has-text-primary">"</span>{{ feedback.message }}<span class="has-text-primary">"</span></p>
        <p class="is-6 is-italic has-text-primary">- {{ feedback.author }}</p>
      </div>
    </div>
    <p class="has-text-centered is-size-7">
      Asiakasarviot perustuvat palvelua käyttäneiltä asiakkailta sähköpostitse pyydettyihin arvioihin.
    </p>
  </div>
</template>

<script>
import Siema from 'siema'

export default {
  props: {
    feedbacks: {
      type: Array,
      default () {
        return [
          {
            message: 'Kiitos, sain useita lainatarjouksia todella nopeasti, joita voin nyt vertailla kaikessa rauhassa. Ei jonotus aikaa pankkineuvojalle. Nopeaan rahan tarpeeseen loisto valinta.',
            author: 'Nainen 54v, Siilinjärvi'
          },
          {
            message: 'Palvelu toimi hyvin ja tarjoukset tuli nopeasti.',
            author: 'Mies 50v, Helsinki'
          },
          {
            message: 'Tein lainatarjoukset teidän kautta ja kaikki toimi hienosti. Sain nopeasti monta tarjousta ja kaksi oikein hyvää, joista toisen lopulta valitsin. Voin suositella palvelua muillekin!',
            author: 'Mies 47v, Nokia'
          },
          {
            message: 'Hyvä palvelu ja selkeä informaatio. Helppo vertailla toimijoita. Kiitos!',
            author: 'Mies 52v, Kuopio'
          },
          {
            message: 'Hyvin toimiva palvelu, jonka käyttäminen on helppoa. Suosittelen.',
            author: 'Mies 53v, Helsinki'
          },
          {
            message: 'Asiat hoituivat nopeasti ja helposti. Kiitos.',
            author: 'Nainen 46, Jyväskylä'
          },
          {
            message: 'Moi! Palvelu on ollut aina kympin arvoista.',
            author: 'Nainen 64, Ylistaro'
          },
          {
            message: 'Hyvin toimivat ja selkeät sivut, josta löytää parhaan tarjouksen.',
            author: 'Mies 68v, Lahti'
          },
          {
            message: 'Nopeaa palvelua ja hyvät yhteistyökumppanit. Lainan korko tippui puoleen entisestä!',
            author: 'Nainen 41v, Oulu'
          },
          {
            message: 'Kiitos hyvästä palvelusta! Olen saanut laina-asiani kuntoon.',
            author: 'Mies 71v, Hämeenlinna'
          },
          {
            message: 'Palvelu on nopeaa ja paljon vaihtoehtoja.',
            author: 'Nainen 54, Lappi'
          },
          {
            message: 'Nopeaa ja tehokasta, kiitos!',
            author: 'Mies 64v, Helsinki'
          },
          {
            message: 'Hienoa että sai hyvän lainatarjouksen helposti ja nopeasti',
            author: 'Mies 31v, Rasivaara'
          },
          {
            message: 'Hei, kiitos mukavasta palvelusta. Hienosti asiat hoituivat ja nyt lainapäätös on jo saatu ja odotellaan suoritusta. Alle 24 tuntia meni koko prosessiin aikaa. Suosittelen lämpimästi pikkulainojen kilpailuttamista asiantuntijoiden toimesta. Suosittelen asioimaan kanssanne.',
            author: 'Nainen 43v, Kuopio'
          },
          {
            message: 'Kiitos hienosta sovelluksesta. Toimii hyvin ja on helppoa sekä nopeata! Tämä säästää eläkeläisen kuluja ja vaivoja todella paljon. ',
            author: 'Mies 65v, Kalkkinen'
          },
          {
            message: 'Kiitos hyvästä ja toimivasta palvelusta!',
            author: 'Nainen 39v, Vantaa'
          },
          {
            message: 'Tämä palvelu oli todella hyvä. Säästin omaa aikaa, kun tarjoukset tuli monesta paikasta yhdellä kertaa. Hienosti kaikki pelasi.',
            author: 'Mies 43v, Oulu'
          },
          {
            message: 'Hei, kiitos nopeasta palvelusta. Hakemuksen teko ja tarjousten vertailu oli vaivatonta ja selkeää. Sain hakemani summan lainaa sopivalla kuukausierällä ja sopivalla korolla.',
            author: 'Mies 51v, Kemiö'
          },
          {
            message: 'Kovin paljon helpommaksi ei lainahakemusta voi enää tehdä. Vaikea on esittää mistä voisi vielä parantaa.',
            author: 'Mies 66v, Akaa'
          },
          {
            message: 'Loistava tapa hakea ja kilpailuttaa pikaisesti haluamasi laina.',
            author: 'Mies 55v, Rovaniemi'
          },
          {
            message: 'Kiitän todella nopeasta ja ammattitaitoisesta palvelusta. Myös puhelimitse. Olen erittäin tyytyväinen kokonaisuuteen.',
            author: 'Nainen 66v, Kuopio'
          },
          {
            message: 'Todella hyvää ja luotettava palvelua. Suosittelen muille varmasti. Kiitos.',
            author: 'Mies 47v, Vantaa'
          },
          {
            message: 'Hei, Olen tyytyväinen Rahalaitoksen palveluun, lainatarjouksia tuli nopeasti ja riittävä määrä.',
            author: 'Mies 59v, Kouvola'
          },
          {
            message: 'Kiitokset paljon erittäin hyvästä palvelusta',
            author: 'Nainen 34 v, Muurame'
          },
          {
            message: 'Oli tosi nopia lainapalvelu, tarjouksia tuli sopivasti ja se oikea löytyi',
            author: 'Mies 46v, Loviisa'
          },
          {
            message: 'Palvelu toimi erittäin hyvin. Varsin kätevä apu kiireiselle ihmiselle. Tarjousten pääkohdat oli esitetty selkeästi ja päätöksenteko oli helppoa. Kiitokset avusta!',
            author: 'Mies 56v, Joensuu'
          },
          {
            message: 'Sivustonne käytettävyys on huikealla tasolla. Onnittelen.',
            author: 'Mies 58v, Lempäälä'
          },
          {
            message: 'Tämä palvelu toimi nopeasti ja joustavasti. voin suositella muillekin.',
            author: 'Nainen 67v, Puuppola'
          },
          {
            message: 'Hyvää ja nopeaa palvelua sekä teiltä että  lainan tarjoajilta! Suosittelen !',
            author: 'Mies 57v, Tampere'
          },
          {
            message: 'Lainan haku kaavakkeilla tehty hyvin ymmärrettäväksi ja johdonmukaiseksi. Tieto lainan käsittelystä ja tuloksista ilmoitetaan nopeasti.',
            author: 'Nainen 73v, Helsinki'
          },
          {
            message: 'Erittäin hyvin toimi kaikki ja todella nopeasti. Suosittelen muillekkin :)',
            author: 'Mies 48v, Sonkajärvi'
          },
          {
            message: 'Kiitos,sain useita lainatarjouksia todella nopeasti,joita voin nyt vertailla kaikessa rauhassa.Ei jonotus aikaa pankkineuvojalle.Nopeaan rahan tarpeeseen loisto valinta',
            author: 'Nainen 54v, Siilinjärvi'
          },
          {
            message: 'Kiitos paljon,toiminta oli ripeää ja ensiluokkaista',
            author: 'Nainen 74v, Lapinlahti'
          },
          {
            message: 'Palvelu toimi hyvin ja tarjoukset tuli nopeasti.',
            author: 'Mies 50v, Helsinki'
          },
          {
            message: 'Tein lainatarjoukset teidän kautta ja kaikki toimi hienosti. Sain nopeasti monta tarjousta ja kaksi oikein hyvää, joista toisen lopulta valitsin. Voin suositella palvelua muillekin!',
            author: 'Mies 47v, Nokia'
          },
          {
            message: 'Hyvä palvelu ja selkeä informaatio. Helppo vertailla toimijoita .Kiitos.',
            author: 'Mies 52v, Kuopio'
          },
          {
            message: 'Hyvä ja nopea palvelu. Kilpailutus joustavasti asiakkaalle tiedoksi. Päätös nopeasti.',
            author: 'Nainen 72v, Helsinki'
          },
          {
            message: 'Hyvin toimiva palvelu jonka käyttäminen on helppoa. Suosittelen.',
            author: 'Mies 53v, Helsinki'
          },
          {
            message: 'Erinomaisen helppo ja nopea palvelu!',
            author: 'Mies 58v, Pieksämäki'
          },
          {
            message: 'Todella hyvää palvelua ja nopeasti tuli ensimmäinen tarjous.',
            author: 'Nainen 55v, Jyväskylä'
          },
          {
            message: 'Asiat hoituivat nopeasti ja helposti. Kiitos.',
            author: 'Nainen 46, Jyväskylä'
          },
          {
            message: 'Moi! Palvelu on ollut aina kympin arvoista.',
            author: 'Nainen 64, Ylistaro'
          },
          {
            message: 'Hyvin toimivat ja selkeät sivut, josta löytää parhaan tarjouksen.',
            author: 'Mies 68v, Lahti'
          },
          {
            message: 'Olen tyytyväinen teidän kanssanne tehtyihin tutkimuksiin lainojen antajista. Kiitos avustanne',
            author: 'Nainen 65v, Lapua'
          },
          {
            message: 'Nopeaa palvelua ja hyvät yhteistyökumppanit . Lainan korko tippui puoleen entisestä!!',
            author: 'Nainen 41v, Oulu'
          },
          {
            message: 'Kiitos hyvästä palvelusta! Olen saanut laina-asiani kuntoon.',
            author: 'Mies 71v, Hämeenlinna'
          }
        ]
      }
    }
  },
  data () {
    return {
      siema: null,
      playing: false,
      timer: null
    }
  },
  mounted () {
    this.$nextTick( () => {
      this.play()
      this.siema = new Siema( {
        selector: this.$refs.slides,
        threshold: 20,
        loop: true
      } )
    } )
  },
  beforeDestroy () {
    if ( this.playing ) clearInterval( this.timer )
    this.siema.destroy()
  },
  methods: {
    play () {
      this.playing = true
      this.timer = setInterval( () => {
        this.siema.next()
      }, 6000 )
    },
    stop () {
      this.playing = false
      clearInterval( this.timer )
    }
  }
}
</script>

<style lang="scss">
  .feedback {
    .slides {
      @include tablet() {
        margin: 0 5em;
      }
      @include desktop() {
        margin: 0 10em;
      }
    }
  }
</style>
