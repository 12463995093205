<template>
  <section class="hero main-hero">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half-desktop">
            <div class="content" style="background-color: white; padding: 3rem; border-radius: 10px;">
              <breadcrumb
                :path="{'Yleiset ehdot': '/yleiset-ehdot', 'Tietoja evästeistä': ''}"
              />
              <h1>Evästeiden käyttö Rahalaitoksen verkkosivuilla</h1>
              <p>Eväste (cookies) on pieni, käyttäjän tietokoneelle tai mobiililaitteelle lähetettävä ja siellä säilytettävä tekstitiedosto. Evästeen tiedot ovat vain tekstitiedoston tallentaneen sivuston käytettävissä. Evästeet eivät vahingoita käyttäjien tietokoneita, mobiililaitteita tai tiedostoja.</p>
              <p>Sivustolla käytetään evästeitä palvelun turvallisuuden, tehokkuuden ja käyttäjäystävällisyyden takaamiseksi.</p>
              <p>Sivustolla käytämme evästeitä tilastollisen tiedon tuottamiseen, A/B testaukseen, sekä mainonnan kohdentamiseen.</p>
              <p>Evästeiden käyttö on mahdollista estää muuttamalla selaimen asetuksia. Mutta tulee kuitenkin ottaa huomioon, että ilman evästeitä et voi käyttää joitakin sivustolla olevia palveluja tai toimintoja.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: 'Tietoja Evästeistä | Rahalaitos.fi',
      meta: [
        { hid: 'description', name: 'description', content: 'Eväste (cookies) on pieni, käyttäjän tietokoneelle tai mobiililaitteelle lähetettävä ja siellä säilytettävä tekstitiedosto. Evästeen tiedot ovat vain tekstitiedoston tallentaneen sivuston käytettävissä.' },
        { hid: 'robots', name: 'robots', content: 'noindex' }
      ]
    }
  }
}
</script>
