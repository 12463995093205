<template>
  <div class="content">
    <breadcrumb
      :path="{'Lainaa': '/lainaa', 'Lainojen kilpailuttaminen verkossa': ''}"
    />
    <div class="subpage_floating">
      <img :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
      <sliders class="is-hidden-touch" />
    </div>
    <h1>Oletko jo kokeillut lainojen kilpailuttamista verkossa?</h1>
    <h5>
      Hotellien, lentojen ja vaikkapa sähkön kilpailuttaminen on useimmille suomalaisille tuttua. Tutustu, miten vaivatonta lainojen kilpailuttaminen on tänä päivänä.
    </h5>
    <p>
      Vielä kymmenen vuotta sitten mahdollisuus kilpailukyisen lainan ottamiseen yllättäviä menoja varten oli kallista tai vaivalloista. Vaihtoehtoina olivat enimmäkseen joko kalliit pikavipit tai postimyyntiyritysten lainat. Myös oma pankki saattoi tarjota lainaa useasti vakuuksia vastaan, kunhan vaan ensin varasit ajan.
    </p>
    <sliders class="is-hidden-desktop" />
    <p>
      Verkko on tuonut suomalaisille enemmän mahdollisuuksia ottaa lainaa silloin, kun sitä tarvitsee. Myös lainojen vertailusta on tullut hyvin yleistä verkossa toimivien lainan kilpailuttajien ansiosta.
    </p>
    <p>
      Verkosta sen sopivimman lainan ottaminen ei ole kuitenkaan yksinkertaisin asia. Googlesta haettaessa nähtävillesi tulee runsaasti vaihtoehtoja ja joudut tekemään valtavan työn tutkiessasi mikä näistä olisi juuri sinulle järkevin vaihtoehto.
    </p>
    <p>
      Aivan kuten lennoissa ja hotelleissa niin myös lainojen kilpailuttamiseen löytyy erikoistuneita palveluita. Yksi näistä on suurin täysin suomalainen lainapalvelu <nuxt-link to="/">Rahalaitos</nuxt-link>. Kerromme seuraavassa, miten olemme kilpailuttaneet jo satojatuhansia lainahakemuksia suomalaisten eduksi.
    </p>
    <h2>
      1. Yhdellä hakemuksella useita tarjouksia
    </h2>
    <p>
      Sen sijaan, että tekisit usealle eri lainantarjoajalle lainahakemuksen teet vain yhden hakemuksen, jonka kilpailutamme hetkessä jopa 25 eri pankin kesken. Kätevää, eikö vaan?
    </p>
    <h2>
      2. Vertaa ja valitse
    </h2>
    <p>
      Saat nähtävillesi jopa minuuteissa sinulle tehdyt lainatarjoukset. Voit vertailla eri tarjouksia kätevästi yhdessä näkymässä ja valita sinulle sopivimman. Jos päätät olla nostamatta lainaa niin ei hätää, et ole sitoutunut mihinkään lainaan ja kilpailutuspalvelu on kuluttajalle täysin ilmaista. Laina vahvistetaan vasta, kun olet hyväksynyt allekirjoituksellasi pankin tarjoamat lainaehdot ja nostanut lainan tilillesi.
    </p>
    <h2>
      3. Nopeaa ja helppoa
    </h2>
    <p>
      Hakemuksen täyttäminen vie vain minuutin. Ota valmiiksi tilitietosi, jolle lainan haluat maksettavan. Myös kaikkien mahdollisten jo olemassa olevien lainojen kuukausierät ja summat olisi hyvä olla käsillä, sillä näillä tiedoilla on merkitystä lainatarjouksiisi.
    </p>
    <p>
      Useimmat lainatarjoukset pääset hyväksymään pankkitunnuksillasi suoraan omilta sivuiltasi, missä tarjoukset ovat nähtävillä. Hakemastasi lainasummasta riippuen, valitsemasi laina maksetaan nopeimmillaan vielä saman päivän aikana tilillesi.
    </p>
    <h2>
      4. Myös nykyisten lainojen yhdistäminen kannattaa
    </h2>
    <p>
      Mikäli sinulla on useita päällekkäisiä kortti- tai kulutusluottoja, kannattaa nämä ehdottomasti <nuxt-link to="/yhdista-lainat">yhdistää</nuxt-link> yhdeksi lainaksi. Tällöin maksat vain yhtä kuukausierää etkä useita päällekkäisiä korko- ja laskutuskuluja. Tämä merkitsee lähes aina merkittävää säästöä kuluissa.
    </p>
    <p>
      <nuxt-link to="/yhdista-lainat"> Kokeile lainojen kilpailuttamista maksutta tästä. </nuxt-link>
    </p>

    <share text="Oletko jo kokeillut lainojen kilpailuttamista verkossa?" />
  </div>
</template>

<script>
import sliders from '~/components/subpage/sliders'
import share from '~/components/subpage/share'

export default {
  layout: 'articles',
  name: 'LainojenKilpailuttaminenVerkossa',
  components: {
    sliders,
    share,
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: 'Oletko jo kokeillut lainojen kilpailuttamista verkossa?',
      meta: [
        { hid: 'description', name: 'description', content: 'Tutustu, miten vaivatonta lainojen kilpailuttaminen on tänä päivänä. Rahalaitoksen kautta voit saada jopa 25 lainatarjousta yhdellä kertaa!' }
      ]
    }
  }
}
</script>
